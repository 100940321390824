<template>
  <div>
    <div class="flex flex-centers">
      <div class="tit1"></div>
      <div class="tit2 ml10">身份证识别</div>
    </div>
    <div class="tit3 mt20">
      对二代居民身份证中的姓名、性别、民族、出生日期、住址、身份证号等关键字段进行结构化识别。
    </div>
    <div class="flex mt40">
      <div class="leftvhtml">
        <div class="imgcontent">
          <div class="distinguishimg" style="text-align: center" v-loading="loading" element-loading-text="处理中，请稍后。。。" element-loading-background="rgba(255, 255, 255, 0.5)">
            <img :src="imgUrlId" alt="" style="max-width: 100%;max-height: 100%">
          </div>
        </div>
        <div class="flex flex-centers flex-space update">
          <div class="tit7">
            图片文件类型支持JPG、PNG、JPEG、BMP，图片大小不超过2M.
          </div>
          <el-upload
              class="btnupdate flex flex-center hoveraa"
              action="https://ai.cicba.cn/ai-api/ai/idCard"
              :data="{cardSide: 'front'}"
              :on-preview="handlePreview"
              :on-success="(res,file)=>{return uploadIDCard(res,file, 'idImg')}"
              :on-error="upImgError"
              :before-upload="beforeUpImg"
              :show-file-list="false">
            <div class="up-img-btn">本地上传</div>
          </el-upload>
        </div>
        <div class="flex flex-centers imgbottom">
          <div class="imgdis flex flex-center flex-column" @click="setImgId(defaultCards.def_IDCard1,1)">
            <img src="../assets/IDcard1.jpg" :class="setClassId ? 'active' : ''" class="updateicon" />
          </div>
          <div class="imgdis flex flex-center flex-column" @click="setImgId(defaultCards.def_IDCard2,2)">
            <img src="../assets/IDcard2.jpg" :class="setClassId2 ? 'active' : ''" class="updateicon" />
          </div>
        </div>
      </div>
      <div class="rightvhtml">
        <div class="tit4">识别结果</div>
        <div class="flex mt60">
          <div class="tit5">姓名</div>
          <div class="tit6">{{idCard.name}}</div>
        </div>
        <div class="flex mt60">
          <div class="tit5">性别</div>
          <div class="tit6">{{ idCard.sex }}</div>
        </div>
        <div class="flex mt60">
          <div class="tit5">民族</div>
          <div class="tit6">{{ idCard.nation }}</div>
        </div>
        <div class="flex mt60">
          <div class="tit5">出生日期</div>
          <div class="tit6">{{ getBirth(idCard.birth) }}</div>
        </div>
        <div class="flex mt60">
          <div class="tit5">住址</div>
          <div class="tit6">{{ idCard.addr }}</div>
        </div>
        <div class="flex mt60">
          <div class="tit5">身份证号</div>
          <div class="tit6">{{ idCard.cardNum }}</div>
        </div>
      </div>

    </div>
    <div class="flex flex-centers mt40">
      <div class="tit1"></div>
      <div class="tit2 ml10">其他</div>
    </div>
    <div class="other-box">
      <div class="other flex flex-center hovera" @click="setType(item.type,index)" v-for="(item, index) in datas" :key="index">
        <div>{{item.name}}</div>
        <img src="../assets/righttip.png" class="righttip ml10" />
      </div>
    </div>
  </div>
</template>
<script>
import def_IDCard1 from "@/assets/IDcard1.jpg";
import def_IDCard2 from "@/assets/IDcard2.jpg";


export default {
  data(){
    return {
      datas: [
        {name: '银行卡识别', type: 'card'}
      ],
      isFirst:true,
      isCard:true,
      isIdFirst:true,
      defaultCards:{
        def_IDCard1,
        def_IDCard2
      },
      imgUrlId: def_IDCard1,
      imgUrl: '',
      loading: false,
      setClass: true,
      setClass2: false,
      setClassId: false,
      setClassId2: false,
      idCard:{
        name:'徐乐乐',
        sex:'男',
        nation:'汉',
        birth:'19661102',
        addr:'安徽省宿州市埇桥区朱仙庄镇',
        cardNum:'652901196611026716',
      },
    }
  },
  methods: {
    setType(type,index){
      this.$emit("chooseType", 1);
    },
    setImgId(card,type) {
      this.imgUrlId = card;
      if (type === 1) {
        this.setClassId = true
        this.setClassId2 = false
        this.isIdFirst = true
        this.idCard.name='徐乐乐'
        this.idCard.sex='男'
        this.idCard.nation='汉'
        this.idCard.birth='19661102'
        this.idCard.addr='安徽省宿州市埇桥区朱仙庄镇'
        this.idCard.cardNum='652901196611026716'
      } else if (type === 2) {
        this.setClassId = false
        this.setClassId2 = true
        this.isIdFirst = false
        this.idCard.name='王飞妃'
        this.idCard.sex='女'
        this.idCard.nation='汉'
        this.idCard.birth='19920818'
        this.idCard.addr='辽宁省大连市甘井子区'
        this.idCard.cardNum='522530199208180048'
      }
    },
    //上传报错返回方法
    upImgError(err, file, fileList) {
      this.$message.error(`${JSON.parse(err.message).msg}`);
    },
    handlePreview(file) {
      console.log(file);
    },
    uploadIDCard(res, file, name) {
      if (res && res.code === 0) {
        this.imgUrlId = 'https://ai.cicba.cn/ai-api' + res.data.originFile
        this.setClassId = false
        this.setClassId2 = false
        this.idCard.name = res.data.responseData.姓名.words
        this.idCard.sex = res.data.responseData.性别.words
        this.idCard.nation = res.data.responseData.民族.words
        this.idCard.birth = res.data.responseData.出生.words
        this.idCard.addr = res.data.responseData.住址.words
        this.idCard.cardNum = res.data.responseData.公民身份号码.words
        this.loading = false
      }else{
        this.loading = false;
        this.$message.error(`${res.msg}`);
      }
    },
    beforeUpImg(file) {
      this.loading = true
      const isJPG = file.type === 'image/jpeg';
      const isGIF = file.type === 'image/gif';
      const isPNG = file.type === 'image/png';
      const isBMP = file.type === 'image/bmp';
      const isLt20M = file.size / 1024 / 1024 < 4;
      if (!isJPG && !isGIF && !isPNG && !isBMP) {
        this.$message.error(`上传图片必须是JPG/GIF/PNG/BMP 格式!`);
        this.loading = false
        return false
      }
      if (!isLt20M) {
        this.$message.error(`上传图片大小不能超过 4MB!`);
        this.loading = false
        return false
      }
      return (isJPG || isGIF || isPNG || isBMP) && isLt20M;
    }
  },
  computed:{
    getBirth(){
      return function(val){
        if(!val) return '';
        val = val + '';
        return val.slice(0,4) + '年' + val.slice(4,6) + '月' + val.slice(6) + '日';
      }
    }
  }
}
</script>
<style scoped>
.other-box:after{
  display: table;
  content: '';
  clear: both;
}
.swipertop {
  width: 100%;
  height: 215px;
  background: #1a5df1;
  padding: 0 360px;
}
.aboutbg1img {
  width: 317px;
  height: 126px;
}
.aboutbg1img1 {
  width: 371px;
  height: 126px;
}
.aboutbg2img1 {
  width: 436px;
  height: 197.1px;
}
.imgdis.active:after{
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  border: 2px solid #2B52F9;
  content: '';
}
.coh1-1 {
  height: 100%;
}
.aboutbg2img {
  width: 436px;
  height: 198.1px;
}
.aboutbg2img2 {
  width: 436px;
  height: 190.2px;
}
.context {
  margin: 38px 0 8px 0;
  padding: 0 360px;
}
.txt1 {
  font-size: 18px;
  color: #1a5df1;
}

.arrowright {
  width: 15px;
  height: 15px;
  transform: rotate(90deg);
  margin: 2px 15px 0 15px;
}

.txt2 {
  font-size: 18px;
  color: #333333;
}
.vhtml {
  width: 100%;
  padding: 30px 40px;
  background: #fff;
  margin-top: 50px;
  position: relative;
}
.tit1 {
  width: 5px;
  height: 5px;
  background: #000000;
  border-radius: 50%;
}
.tit2 {
  font-size: 22px;
  font-weight: bold;
  color: #333333;
}
.tit3 {
  font-size: 16px;
  color: #9da3ab;
}
.aibgimg {
  position: absolute;
  z-index: 0;
  width: 311px;
  height: 227px;
  top: 116px;
  right: 0;
}
.leftvhtml {
  width: calc(100% - 376px);
  border: 1px solid #cccccc;
}
.allvhtml {
  padding: 27px 27px 0 27px;
  background: #e9edfe;
  position: relative;
  z-index: 1;
}
.rightvhtml {
  width: 376px;
  padding: 70px 20px 20px 50px;
  background: #2b52f9;
  position: relative;
  z-index: 1;
}
.other {
  margin-top: 30px;
  width: 220px;
  height: 55px;
  background: rgba(43, 82, 249, 0.05);
  line-height: 55px;
  text-align: center;
  font-size: 20px;
  color: #205cf4;
}
.tit4 {
  font-size: 20px;
  color: #ffffff;
  line-height: 30px;
}
.tit5 {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  line-height: 30px;
  opacity: 0.5;
  width: 110px;
}
.tit6 {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  line-height: 30px;
  opacity: 0.98;
  width: calc(100% - 110px);
}
.imgcontent {
  padding: 62px 92px;
}
.imgcontent2 {
  padding: 55px 50px;
}
.distinguishimg {
  width: 100%;
  height: 334px;
}
.update {
  padding: 0 50px;
  height: 68px;
  background: rgba(43, 82, 249, 0.05);
}
.tit7 {
  font-size: 14px;
  color: #333333;
  opacity: 0.5;
}
.btnupdate {
  width: 104px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  background: #2b52f9;
  font-size: 14px;
  color: #ffffff;
  line-height: 30px;
}
.imgbottom {
  padding: 30px 50px;
}
.imgdis {
  background: RGBA(243, 245, 254, 1);
  width: 220px;
  height: 142px;
  margin-right: 30px;
}
.updateicon {
  width: 100%;
  height: 100%;
}
.updatetext {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
  margin-top: 20px;
}
.imgdiss {
  background: #f3f5fe;
  padding: 25px 20px;
}
.imgdis:last-child {
  margin-right: 0;
}
.imgcontent1 {
  width: 50%;
}
.imgd {
  width: 100%;
  height: 359px;
}
.handle {
  width: 99px;
  height: 38px;
  line-height: 38px;
  border: 2px solid #bfbfbf;
  text-align: center;
  color: #bfbfbf;
  font-size: 16px;
}
.h100 {
  height: 100px;
}
.b {
  border: 1px solid #cccccc;
}
.col3-1 {
  width: 33.33%;
}
.tit8 {
  font-size: 17px;
  font-weight: bold;
  color: #333333;
}
.tit9 {
  font-size: 15px;
  color: #333333;
  line-height: 24px;
  opacity: 0.5;
}
.flex3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
</style>
