<template>
    <div>
          <div class="flex flex-centers">
            <div class="tit1"></div>
            <div class="tit2 ml10">新闻摘要提取</div>
          </div>
          <div class="tit3 mt20">
            根据新闻标题及正文内容，自动提取新闻文本中的关键字。
          </div>
          <div class="flex mt40">
            <div class="leftvhtml">
              <div class="imgcontent2">
                <div class="tit8">
                  <input type="text" v-model="title" autocomplete="off" placeholder="标题" id="cmpName">
                </div>
                <div class="tit9 mt20">
                  <textarea v-model="content" style="width: 29.010vw;height: 17.396vw;margin: 0 auto;" placeholder="内容" id="content"></textarea>
                </div>
              </div>
              <div class="flex flex-centers flex-space update">
                <div class="tit7">
                </div>
                <div style="cursor: pointer" @click="jiexi" class="btnupdate flex flex-center hoveraa">解析</div>
              </div>
              <div class="flex flex-centers imgbottom">
                <div class="imgdis imgdiss" :class="setClass ? 'active' : ''"
                     @click="setImg(1)">
                  <div class="tit8">{{title1}}</div>
                  <div class="tit9 flex3 mt5">{{content1}}</div>
                </div>
                <div class="imgdis imgdiss" :class="setClass2 ? 'active' : ''"
                     @click="setImg(2)">
                  <div class="tit8">{{title2}}</div>
                  <div class="tit9 flex3 mt5">{{content2}}</div>
                </div>
              </div>
            </div>
            <div class="rightvhtml">
              <div class="tit4">解析结果</div>
              <div class="mt60" style="font-size: 16px; color: #ffffff">
                {{getVal}}
<!--                <div class="tit5">关键字</div>-->
<!--                <div class="flex flex-wrap">-->
<!--                  <div class="tit6 col3-1 mt40">字段1</div>-->
<!--                  <div class="tit6 col3-1 mt40">字段1</div>-->
<!--                  <div class="tit6 col3-1 mt40">字段1</div>-->
<!--                  <div class="tit6 col3-1 mt40">字段1</div>-->
<!--                  <div class="tit6 col3-1 mt40">字段1</div>-->
<!--                </div>-->
              </div>
            </div>
          </div>
        </div>
</template>
<script>
export default {
  data(){
    return{
      getVal: '',
      loading: false,
      imgUrl: '',
      imgUrla: '',
      setClass: true,
      setClass2: false,
      title: '',
      content: '',
      title1: '含谷镇：当好发展急先锋，开创高质量发展新局面',
      title2: '2020“智博会”服务数字政府与优化营商环境高峰论坛在重庆举行',
      content1: '建设智慧名城，重庆正加快脚步。日前，重庆高新区含谷镇联合重庆大数据人工智能创新中心，进行含谷镇智慧政府管理平台系统建设，全力助推含谷镇“数智化”治理发展进程。\n' +
          '\n' +
          '智慧政府管理平台系统采用数字孪生技术，在实时渲染3D电子沙盘上，接入政府综合治理的业务数据，包括企业工商数据、环保执法数据、安监执法数据、城管执法数据、农业和森林防火摄像头流媒体数据等。平台将实现“3 5 1管理体系”，构建包含“地理信息库、自然人库、法人库”3个数据库，“内部办公及考核、平安综治、环保执法、森林防火、智慧党建系统”5大系统，以及1个应急指挥中心。\n' +
          '\n' +
          '智慧政府管理平台作为智慧城市的基础设施，通过三维成像与大数据技术，结合人工智能算法，更加立体、直观、全面的掌握辖区内企业、自然资源和行政设施等对象的整体运行和变化情况，为智慧规划、智慧交通、智慧水务、应急指挥等模块的科学管理和决策制订提供数字依据。\n' +
          '\n' +
          '平台全面建成后，将形成“城市一盘棋”集中治理模式，打造实体城市与数字孪生城市的数据映射和展示平台，实现跨层级、跨地域、跨系统、跨部门、跨业务的城市治理协同，构建全域覆盖、全程可控、精准监测、高效处置的城市智能治理体系。',
      content2: '9月15日下午，2020线上中国国际智能产业博览会（以下简称“智博会”）“服务数字政府与优化营商环境高峰论坛”在重庆举行。中国建设银行行长刘桂平、党委委员王浩、首席风险官靳彦民、信息总监金磐石等在主会场出席论坛，刘桂平发表主旨演讲。来自全国23个省市政务主管部门的领导，业界专家、学者和企业高管，建设银行总行相关部门、部分分行负责人现场参加论坛。建设银行37家一级分行和当地政务主管部门与主会场实时连线参加。\n' +
          '\n' +
          '刘桂平指出，智慧政务在优化营商环境、改善民生保障、精简政务流程和实现数字化发展等方面具有重要现实意义，是实现高质量发展的重要平台，是发挥政府引领作用的重要载体，也是建设银行新金融实践的重要内涵。建设银行将智慧政务上升为全行发展战略，两年多来，已与28个省级和130个地市级政府签订智慧政务战略合作协议，合力打造政银共建智慧政务生态圈。其中，由建设银行承担总集成建设的“渝快办”，形成了重庆特色、打造了“重庆模式”，是政银联手、紧密合作的典范之作。\n' +
          '\n' +
          '刘桂平分享了建设银行智慧政务赋能营商环境优化的四点体会。一是智慧政务为加强民生保障做加法，通过优化办事流程、完善服务渠道、创新服务方式，用金融力量提升民生保障水平。二是智慧政务为精简政务流程做减法，助力政府重构治理体系、技术体系和数据体系，共同构建标准化、便捷化、精简化的政务服务流程。三是智慧政务为实现数据资产化应用做乘法，通过构建统一数据平台，破除数据烟囱，深挖数据资产价值，降低市场交易成本，提升数据治理能级，不断推动数据赋能国家治理。四是智慧政务为完善政府监管督察做除法，助力加强对政府办事部门的监督管理，打造“阳光政府”，实现对政务办事流程、政府监管机构和重要督察事项的监管。\n' +
          '\n' +
          '刘桂平强调，建设银行将积极拥抱数字经济时代，发挥金融科技优势，聚焦营商环境改善，努力从平台建设者转变为创新引领者、从能力输出者转变为模式开创者、从资源整合者转变为生态构建者，为推动“数字重庆”“数字中国”建设贡献更多智慧和力量。\n' +
          '\n' +
          '论坛上，中国建设银行与新华通讯社经济参考报、中国社会科学院信息化研究中心、北京国脉互联信息顾问有限公司等机构联合发布《2020城市营商环境高质量发展指数报告》。中国建设银行智慧政务专家咨询委员会正式成立，刘桂平为受聘专家委员颁发了聘书。中国工程院院士柴洪峰、中央党校（国家行政学院）教授汪玉凯、建设银行信息总监金磐石等11位专家在论坛上发表了主题演讲。\n' +
          '\n' +
          '9月14日至15日，刘桂平、王浩一行见证了重庆市政府“渝快办”网上办事大厅新版上线，出席中西部首家5G⁺智能银行——建设银行重庆两江5G⁺智能银行启动仪式。',
    }
  },
  methods:{
    setImg(type) {
      if (type === 1) {
        this.setClass = true
        this.setClass2 = false
        this.title = this.title1
        this.content = this.content1
        this.getVal = '建设智慧名城，重庆正加快脚步。日前，重庆高新区含谷镇联合重庆大数据人工智能创新中心，进行含谷镇智慧政府管理平台系统建设，全力助推含谷镇“数智化”治理发展进程。平台全面建成后，将形成“城市一盘棋”集中治理模式，打造实体城市与数字孪生城市的数据映射和展示平台，实现跨层级、跨地域、跨系统、跨部门、跨业务的城市治理协同，构建全域覆盖、全程可控、精准监测、高效处置的城市智能治理体系。'
      } else if (type === 2) {
        this.setClass = false
        this.setClass2 = true
        this.title = this.title2
        this.content = this.content2
        this.getVal = '9月15日下午，2020线上中国国际智能产业博览会“服务数字政府与优化营商环境高峰论坛”在重庆举行。中国建设银行行长刘桂平、党委委员王浩、首席风险官靳彦民、信息总监金磐石等在主会场出席论坛，刘桂平发表主旨演讲。来自全国23个省市政务主管部门的领导，业界专家、学者和企业高管，建设银行总行相关部门、部分分行负责人现场参加论坛。建设银行37家一级分行和当地政务主管部门与主会场实时连线参加。'
      }
    },
    jiexi() {
      let params = {
        title: this.title,
        content: this.content
      }
      this.$http.newsScreen(params).then(res=>{
        if (res.code === 0) {
          this.getVal = res.data
        } else {
          this.$message.error(`${res.msg}`);
        }
      });
    }
  },
  created(){
    this.setImg(1);
  }
}
</script>
<style scoped>
.swipertop {
  width: 100%;
  height: 215px;
  background: #1a5df1;
  padding: 0 360px;
}
.aboutbg1img {
  width: 317px;
  height: 126px;
}
.aboutbg1img1 {
  width: 371px;
  height: 126px;
}
.aboutbg2img1 {
  width: 436px;
  height: 197.1px;
}
.coh1-1 {
  height: 100%;
}
.aboutbg2img {
  width: 436px;
  height: 198.1px;
}
.aboutbg2img2 {
  width: 436px;
  height: 190.2px;
}
.context {
  margin: 38px 0 8px 0;
  padding: 0 360px;
}
.txt1 {
  font-size: 18px;
  color: #1a5df1;
}

.arrowright {
  width: 15px;
  height: 15px;
  transform: rotate(90deg);
  margin: 2px 15px 0 15px;
}

.txt2 {
  font-size: 18px;
  color: #333333;
}
.vhtml {
  width: 100%;
  padding: 30px 40px;
  background: #fff;
  margin-top: 50px;
  position: relative;
}
.tit1 {
  width: 5px;
  height: 5px;
  background: #000000;
  border-radius: 50%;
}
.tit2 {
  font-size: 22px;
  font-weight: bold;
  color: #333333;
}
.tit3 {
  font-size: 16px;
  color: #9da3ab;
}
.aibgimg {
  position: absolute;
  z-index: 0;
  width: 311px;
  height: 227px;
  top: 116px;
  right: 0;
}
.leftvhtml {
  width: calc(100% - 376px);
  border: 1px solid #cccccc;
}
.allvhtml {
  padding: 27px 27px 0 27px;
  background: #e9edfe;
  position: relative;
  z-index: 1;
}
.rightvhtml {
  width: 376px;
  padding: 70px 20px 20px 40px;
  background: #2b52f9;
  position: relative;
  z-index: 1;
}
.other {
  margin-top: 30px;
  width: 220px;
  height: 55px;
  background: rgba(43, 82, 249, 0.05);
  line-height: 55px;
  text-align: center;
  font-size: 20px;
  color: #205cf4;
}
.tit4 {
  font-size: 20px;
  color: #ffffff;
  line-height: 30px;
}
.tit5 {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  line-height: 30px;
  opacity: 0.5;
  width: 110px;
}
.tit6 {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  line-height: 30px;
  opacity: 0.98;
  width: calc(100% - 110px);
}
.imgcontent {
  padding: 62px 92px;
}
.imgcontent2 {
  padding: 55px 50px;
}
.distinguishimg {
  width: 100%;
  height: 334px;
}
.update {
  padding: 0 50px;
  height: 68px;
  background: rgba(43, 82, 249, 0.05);
}
.tit7 {
  font-size: 14px;
  color: #333333;
  opacity: 0.5;
}
.btnupdate {
  width: 104px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  background: #2b52f9;
  font-size: 14px;
  color: #ffffff;
  line-height: 30px;
}
.imgbottom {
  padding: 30px 50px;
}
.imgdis {
  width: 220px;
  height: 182px;
  margin-right: 30px;
  cursor: pointer;
}
.imgdiss {
  background: #f3f5fe;
  padding: 25px 20px;
}
.imgdis:last-child {
  margin-right: 0;
}
.imgcontent1 {
  width: 50%;
}
.imgd {
  width: 100%;
  height: 359px;
}
.handle {
  width: 99px;
  height: 38px;
  line-height: 38px;
  border: 2px solid #bfbfbf;
  text-align: center;
  color: #bfbfbf;
  font-size: 16px;
}
.h100 {
  height: 100px;
}
.b {
  border: 1px solid #cccccc;
}
.col3-1 {
  width: 33.33%;
}
.tit8 {
  font-size: 17px;
  font-weight: bold;
  color: #333333;
}
.tit9 {
  font-size: 15px;
  color: #333333;
  line-height: 28px;
  opacity: 0.5;
}
.flex3{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
textarea {
  box-sizing: border-box;
  width: 29.010vw;
  height: 17.396vw;
  border: 0.052vw solid rgba(0, 0, 0, .3);
  padding: 0.781vw;
  background: transparent;
}
input {
  width: 28.958vw;
  height: 2.083vw;
  border: 0.052vw solid rgba(0, 0, 0, .3);
  background: transparent;
  margin-bottom: 0.163vw;
  text-indent: 1em;
  box-sizing: border-box;
}
</style>
