<template>
  <div>
    <defaulttop active="2"></defaulttop>
    <div class="swipertop flex flex-space">
      <div class="coh1-1 flex flex-centers">
        <img src="../assets/servicesbg1.png" class="aboutbg1img1" />
      </div>
      <div class="coh1-1 flex flex-bottom">
        <img src="../assets/servicesbg2.png" class="aboutbg2img2" />
      </div>
    </div>
    <div class="context">
      <div class="flex flex-centers" >
        <span class="txt1" style="cursor: pointer" @click="goback">生态服务能力</span>
        <img src="../assets/arrow.png" class="arrowright" />
        <span class="txt2" style="color: #1a5df1;cursor: pointer" @click="gotoAI">AI</span>
        <img src="../assets/arrow.png" class="arrowright" />
        <span class="txt2">
          {{ titlelist[type] }}
        </span>
      </div>
      <div class="vhtml">
        <img src="../assets/aibg.png" class="aibgimg" />
        <idcard v-if="type == 0" @chooseType="chooseType"></idcard>
        <bankcard v-if="type == 1" @chooseType="chooseType"></bankcard>
        <portrait v-if="type == 2"></portrait>
        <defoggingtreatment v-if="type == 3"></defoggingtreatment>
        <newssummary v-if="type == 4"></newssummary>
      </div>
    </div>
  </div>
</template>

<script>
import defaulttop from "../components/defaulttop";
import idcard from "../components/idcard"; //身份证识别
import bankcard from "../components/bankcard"; //银行卡识别
import portrait from "../components/portrait"; //人像识别
import defoggingtreatment from "../components/defoggingtreatment"; //去雾处理
import newssummary from "../components/newssummary"; //新闻摘要提取
export default {
  name: "AiDetails",
  data() {
    return {
      titlelist: [
        "身份证识别",
        "银行卡识别",
        "人像识别",
        "去雾处理",
        "新闻摘要提取",
      ],
      type: 0,
      otherlist: [{
        title: '人像识别',
        path: ''
      }], //样式是按照4个处理的
    };
  },
  components: {
    defaulttop,
    idcard,
    bankcard,
    portrait,
    defoggingtreatment,
    newssummary,
  },
  created() {
    this.type = this.$route.query.type;
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
  mounted() {},
  methods: {
    chooseType(type){
      this.type = type;
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    // 返回
    goback() {
      this.$router.go(-1);
    },
    gotoAI(){
      this.$router.push({
        path:'/services',
        query:{
          classid: 999
        }
      })
    }
  },
};
</script>
<style scoped>
.swipertop {
  width: 100%;
  height: 215px;
  background: #1a5df1;
  padding: 0 360px;
}
.aboutbg1img {
  width: 317px;
  height: 126px;
}
.aboutbg1img1 {
  width: 371px;
  height: 126px;
}
.aboutbg2img1 {
  width: 436px;
  height: 197.1px;
}
.coh1-1 {
  height: 100%;
}
.aboutbg2img {
  width: 436px;
  height: 198.1px;
}
.aboutbg2img2 {
  width: 436px;
  height: 190.2px;
}
.context {
  margin: 38px 0 8px 0;
  padding: 0 360px;
}
.txt1 {
  font-size: 18px;
  color: #1a5df1;
}

.arrowright {
  width: 15px;
  height: 15px;
  transform: rotate(90deg);
  margin: 2px 15px 0 15px;
}

.txt2 {
  font-size: 18px;
  color: #333333;
}
.vhtml {
  width: 100%;
  padding: 30px 40px;
  background: #fff;
  margin-top: 50px;
  position: relative;
}
.tit1 {
  width: 5px;
  height: 5px;
  background: #000000;
  border-radius: 50%;
}
.tit2 {
  font-size: 22px;
  font-weight: bold;
  color: #333333;
}
.tit3 {
  font-size: 16px;
  color: #9da3ab;
}
.aibgimg {
  position: absolute;
  z-index: 0;
  width: 311px;
  height: 227px;
  top: 116px;
  right: 0;
}
.leftvhtml {
  width: calc(100% - 376px);
  border: 1px solid #cccccc;
}
.allvhtml {
  padding: 27px 27px 0 27px;
  background: #e9edfe;
  position: relative;
  z-index: 1;
}
.rightvhtml {
  width: 376px;
  padding: 70px 10px 20px 50px;
  background: #2b52f9;
  position: relative;
  z-index: 1;
}
.other {
  margin-top: 30px;
  width: 220px;
  height: 55px;
  background: rgba(43, 82, 249, 0.05);
  text-align: center;
  font-size: 20px;
  color: #205cf4;
}
.tit4 {
  font-size: 20px;
  color: #ffffff;
  line-height: 30px;
}
.tit5 {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  line-height: 30px;
  opacity: 0.5;
  width: 110px;
}
.tit6 {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  line-height: 30px;
  opacity: 0.98;
  width: calc(100% - 110px);
}
.imgcontent {
  padding: 62px 92px;
}
.imgcontent2 {
  padding: 55px 50px;
}
.distinguishimg {
  width: 100%;
  height: 334px;
}
.update {
  padding: 0 50px;
  height: 68px;
  background: rgba(43, 82, 249, 0.05);
}
.tit7 {
  font-size: 14px;
  color: #333333;
  opacity: 0.5;
}
.btnupdate {
  width: 104px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  background: #2b52f9;
  font-size: 14px;
  color: #ffffff;
  line-height: 30px;
}
.imgbottom {
  padding: 30px 50px;
}
.imgdis {
  width: 220px;
  height: 142px;
  margin-right: 30px;
}
.imgdiss {
  background: #f3f5fe;
  padding: 25px 20px;
}
.imgdis:last-child {
  margin-right: 0;
}
.imgcontent1 {
  width: 50%;
}
.imgd {
  width: 100%;
  height: 359px;
}
.handle {
  width: 99px;
  height: 38px;
  line-height: 38px;
  border: 2px solid #bfbfbf;
  text-align: center;
  color: #bfbfbf;
  font-size: 16px;
}
.h100 {
  height: 100px;
}
.b {
  border: 1px solid #cccccc;
}
.col3-1 {
  width: 33.33%;
}
.tit8 {
  font-size: 17px;
  font-weight: bold;
  color: #333333;
}
.tit9 {
  font-size: 15px;
  color: #333333;
  line-height: 24px;
  opacity: 0.5;
}
.flex3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.righttip{
  width: 10px;
  height: 10px;
  margin-top: 3px;
}
</style>
