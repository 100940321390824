<template>
  <div>
    <div class="flex flex-centers">
      <div class="tit1"></div>
      <div class="tit2 ml10">{{title}}</div>
    </div>
    <div class="tit3 mt20">对图片进行识别，检测与提取图片中的主体目标。</div>
    <div class="flex mt40">
      <div class="leftvhtml">
        <div class="imgcontent">
          <div class="distinguishimg flex flex-center">
            <img :src="imgUrl" style="max-height: 100%;max-width: 100%;" />
          </div>
        </div>
        <div class="flex flex-centers flex-space update">
          <div class="tit7">
            图片文件类型支持JPG、PNG、JPEG、BMP，图片大小不超过2M.
          </div>
          <el-upload
              class="btnupdate flex flex-center hoveraa"
              action="https://ai.cicba.cn/ai-api/ai/detection/det"
              :on-preview="handlePreview"
              :on-success="upImgSuccess"
              :on-error="upImgError"
              :before-upload="beforeUpImg"
              multiple
              :data="fileData"
              :show-file-list="false">
            <div class="up-img-btn">本地上传</div>
          </el-upload>
        </div>
        <div class="flex flex-centers imgbottom">
          <div class="imgdis flex flex-center flex-column" v-for="(item,index) in dataimg"
               :class="index === activelist ? 'active' : ''"
               @click="setImg(item,index)">
            <img :src="item.type == fileData.targetType ? item.url : ''" class="updateicon" />
          </div>
        </div>
      </div>
      <div class="rightvhtml">
        <div class="tit4">识别结果</div>
        <div class="flex mt60">
          <p class="tit6" v-for="item in getVal">{{item}}</p>
<!--          <div class="tit5">人脸数量</div>-->
<!--          <div class="tit6">{{}}</div>-->
        </div>
      </div>
    </div>
    <div class="flex flex-centers mt40">
      <div class="tit1"></div>
      <div class="tit2 ml10">其他</div>
    </div>
    <div class="other-box">
      <div class="other flex flex-center hovera" @click="setType(item.type,index)" v-if="active!=index" v-for="(item, index) in datas" :key="index">
        <div>{{item.name}}</div>
        <img src="../assets/righttip.png" class="righttip ml10" />
      </div>
    </div>
  </div>
</template>
<script>
import people1 from "@/assets/people1.jpg";
import people2 from "@/assets/people2.jpg";
import peoplea from "@/assets/peoplea.jpg";
import peoplea2 from "@/assets/peoplea2.jpg";
import car from "@/assets/car.jpg";
import cara from "@/assets/cara.jpg";
import bicycle from "@/assets/bicycle.jpg";
import bicyclea from "@/assets/bicyclea.jpg";
import dogs from "@/assets/dogs.jpg";
import dogsa from "@/assets/dogsa.jpg";
import birds from "@/assets/birds.jpg";
import birdsa from "@/assets/birdsa.jpg";
import apples from "@/assets/apples.jpg";
import applesa from "@/assets/applesa.jpg";
import oranges from "@/assets/oranges.jpg";
import orangesa from "@/assets/orangesa.jpg";
import furniture1 from "@/assets/furniture1.jpg";
import furniture1a from "@/assets/furniture1a.jpg";
import furniture2 from "@/assets/furniture2.jpg";
import furniture2a from "@/assets/furniture2a.jpg";
import microwave from "@/assets/microwave.jpg";
import microwavea from "@/assets/microwavea.jpg";
import refrigerator from "@/assets/refrigerator.jpg";
import refrigeratora from "@/assets/refrigeratora.jpg";

export default {
  data(){
    return {
      datas: [
        {name: '人像识别', type: 'person'},
        {name: '车辆识别', type: 'car'},
        {name: '动物识别', type: 'animal'},
        {name: '水果识别', type: 'fruit'},
        {name: '家具识别', type: 'furniture'},
        {name: '电器识别', type: 'appliance'},
      ],
      getVal: '',
      loading: false,
      imgUrl: '',
      imgUrla: '',
      setClass: true,
      setClass2: false,
      title: '',
      content: '',
      // type: 1,
      fileData: {
        targetType: 'person'
      },
      active: 0,
      activelist: 0,
      datalist: [
        {url: people1, urlA: peoplea, type: 'person',data:['人: 1']},
        {url: people2, urlA: peoplea2, type: 'person',data:['人: 3']},
        {url: bicycle, urlA: bicyclea, type: 'car',data:['公交车: 1']},
        {url: car, urlA: cara, type: 'car',data:['汽车: 1']},
        {url: dogs, urlA: dogsa, type: 'animal',data:['狗: 2']},
        {url: birds, urlA: birdsa, type: 'animal',data:['猫: 1']},
        {url: apples, urlA: applesa, type: 'fruit',data:['苹果: 4']},
        {url: oranges, urlA: orangesa, type: 'fruit',data:['橙子: 3']},
        {url: furniture1, urlA: furniture1a, type: 'furniture',data:['椅子: 2','桌子: 1','沙发：1']},
        {url: furniture2, urlA: furniture2a, type: 'furniture',data:['床: 1']},
        {url: refrigerator, urlA: refrigeratora, type: 'appliance',data:['电视: 1']},
        {url: microwave, urlA: microwavea, type: 'appliance',data:['冰箱: 1','烤箱：1']},
      ],
      dataimg: [
        {url: people1, urlA: peoplea, type: 'person',data:['人: 3']},
        {url: people2, urlA: peoplea2, type: 'person',data:['人: 4']},
      ],
    }
  },
  methods:{
    setType(type, index) {
      this.active = index
      this.activelist = 0
      this.title = this.datas[index].name;
      this.fileData.targetType = type
      this.dataimg = []
      for (let i = 0; i < this.datalist.length; i++) {
        if (this.datalist[i].type == type) {
          this.dataimg.push({
            url: this.datalist[i].url,
            urlA: this.datalist[i].urlA,
            type: this.datalist[i].type,
            data: this.datalist[i].data
          })
        }
      }
      this.imgUrl = this.dataimg[0].urlA
      this.getVal = this.dataimg[0].data
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    setImg(item, index) {
      this.activelist = index
      this.imgUrl = item.urlA
      this.getVal = item.data
    },
    //上传报错返回方法
    upImgError(err, file, fileList) {
      this.$message.error(`${JSON.parse(err.message).msg}`);
    },
    handlePreview(file) {
      console.log(file);
    },
    upImgSuccess(res, file) {
      if (res && res.code === 0) {
        this.imgUrl = 'https://ai.cicba.cn/ai-api' + res.data.responseData.picUrl
        this.getVal = res.data.responseData.nums
        this.loading = false
      } else {
        this.loading = false
        this.$message.error(`${res.msg}`);
      }
    },
    beforeUpImg(file) {
      this.loading = true
      const isJPG = file.type === 'image/jpeg';
      const isGIF = file.type === 'image/gif';
      const isPNG = file.type === 'image/png';
      const isBMP = file.type === 'image/bmp';
      const isLt20M = file.size / 1024 / 1024 < 4;
      if (!isJPG && !isGIF && !isPNG && !isBMP) {
        this.$message.error(`上传图片必须是JPG/GIF/PNG/BMP 格式!`);
        this.loading = false
        return false
      }
      if (!isLt20M) {
        this.$message.error(`上传图片大小不能超过 4MB!`);
        this.loading = false
        return false
      }
      return (isJPG || isGIF || isPNG || isBMP) && isLt20M;
    },
  },
  mounted() {
    this.setType('person', 0);
    this.imgUrl = this.dataimg[0].urlA
    this.getVal = this.dataimg[0].data
    this.title = this.datas[0].name;
  }
}
</script>
<style scoped>
.swipertop {
  width: 100%;
  height: 215px;
  background: #1a5df1;
  padding: 0 360px;
}
.aboutbg1img {
  width: 317px;
  height: 126px;
}
.aboutbg1img1 {
  width: 371px;
  height: 126px;
}
.aboutbg2img1 {
  width: 436px;
  height: 197.1px;
}
.coh1-1 {
  height: 100%;
}
.aboutbg2img {
  width: 436px;
  height: 198.1px;
}
.aboutbg2img2 {
  width: 436px;
  height: 190.2px;
}
.context {
  margin: 38px 0 8px 0;
  padding: 0 360px;
}
.txt1 {
  font-size: 18px;
  color: #1a5df1;
}

.arrowright {
  width: 15px;
  height: 15px;
  transform: rotate(90deg);
  margin: 2px 15px 0 15px;
}

.other-box:after{
  display: table;
  content: '';
  clear: both;
}

.txt2 {
  font-size: 18px;
  color: #333333;
}
.vhtml {
  width: 100%;
  padding: 30px 40px;
  background: #fff;
  margin-top: 50px;
  position: relative;
}
.tit1 {
  width: 5px;
  height: 5px;
  background: #000000;
  border-radius: 50%;
}
.tit2 {
  font-size: 22px;
  font-weight: bold;
  color: #333333;
}
.tit3 {
  font-size: 16px;
  color: #9da3ab;
}
.aibgimg {
  position: absolute;
  z-index: 0;
  width: 311px;
  height: 227px;
  top: 116px;
  right: 0;
}
.leftvhtml {
  width: calc(100% - 376px);
  border: 1px solid #cccccc;
}
.allvhtml {
  padding: 27px 27px 0 27px;
  background: #e9edfe;
  position: relative;
  z-index: 1;
}
.rightvhtml {
  width: 376px;
  padding: 70px 10px 20px 50px;
  background: #2b52f9;
  position: relative;
  z-index: 1;
}
.other {
  margin-top: 20px;
  width: 220px;
  height: 55px;
  background: rgba(43, 82, 249, 0.05);
  line-height: 55px;
  text-align: center;
  font-size: 20px;
  color: #205cf4;
  margin-left: calc(calc(100% - 4*220px)/3);
  float: left;
}
.other:nth-child(1),
.other:nth-child(2),
.other:nth-child(3),
.other:nth-child(4)
{
  margin-top: 30px;
}
.other:nth-child(1),.other:nth-child(5){
  margin-left: 0;
}

.tit4 {
  font-size: 20px;
  color: #ffffff;
  line-height: 30px;
}
.tit5 {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  line-height: 30px;
  opacity: 0.5;
  width: 110px;
}
.tit6 {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  line-height: 30px;
  opacity: 0.98;
  width: calc(100% - 110px);
}
.imgcontent {
  padding: 62px 92px;
}
.imgcontent2 {
  padding: 55px 50px;
}
.distinguishimg {
  width: 100%;
  height: 334px;
}
.update {
  padding: 0 50px;
  height: 68px;
  background: rgba(43, 82, 249, 0.05);
}
.tit7 {
  font-size: 14px;
  color: #333333;
  opacity: 0.5;
}
.btnupdate {
  width: 104px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  background: #2b52f9;
  font-size: 14px;
  color: #ffffff;
  line-height: 30px;
}
.imgbottom {
  padding: 30px 50px;
}
.imgdis {
  background: RGBA(243, 245, 254, 1);
  width: 220px;
  height: 142px;
  margin-right: 30px;
  position: relative;
}
.imgdis.active:after{
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  border: 2px solid #2B52F9;
  content: '';
}
.updateicon {
  width: 100%;
  height: 100%;
}
.updatetext {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
  margin-top: 20px;
}
.imgdiss {
  background: #f3f5fe;
  padding: 25px 20px;
}
.imgdis:last-child {
  margin-right: 0;
}
.imgcontent1 {
  width: 50%;
}
.imgd {
  width: 100%;
  height: 359px;
}
.handle {
  width: 99px;
  height: 38px;
  line-height: 38px;
  border: 2px solid #bfbfbf;
  text-align: center;
  color: #bfbfbf;
  font-size: 16px;
}
.h100 {
  height: 100px;
}
.b {
  border: 1px solid #cccccc;
}
.col3-1 {
  width: 33.33%;
}
.tit8 {
  font-size: 17px;
  font-weight: bold;
  color: #333333;
}
.tit9 {
  font-size: 15px;
  color: #333333;
  line-height: 24px;
  opacity: 0.5;
}
.flex3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.righttip{
  width: 10px;
  height: 10px;
  margin-top: 3px;
}
</style>
