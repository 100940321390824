<template>
  <div>
    <div class="flex flex-centers">
      <div class="tit1"></div>
      <div class="tit2 ml10">银行卡识别</div>
    </div>
    <div class="tit3 mt20">
      对银行卡中的银行卡卡号、有效期、民族、银行名称、银行卡类型等关键字段进行结构化识别。
    </div>
    <div class="flex mt40">
      <div class="leftvhtml">
        <div class="imgcontent">
          <div class="distinguishimg" style="text-align: center" v-loading="loading" element-loading-text="处理中，请稍后。。。" element-loading-background="rgba(255, 255, 255, 0.5)">
            <img :src="imgUrl" alt="" style="max-width: 100%;max-height: 100%">
          </div>
        </div>
        <div class="flex flex-centers flex-space update">
          <div class="tit7">
            图片文件类型支持JPG、PNG、JPEG、BMP，图片大小不超过2M.
          </div>
          <el-upload
              class="btnupdate flex flex-center hoveraa"
              action="https://ai.cicba.cn/ai-api/ai/bankCard"
              :data="{cardSide: 'front'}"
              :on-preview="handlePreview"
              :on-success="(res,file)=>{return uploadCard(res,file, 'idImg')}"
              :on-error="upImgError"
              :before-upload="beforeUpImg"
              :show-file-list="false">
            <div class="up-img-btn">本地上传</div>
          </el-upload>
        </div>
        <div class="flex flex-centers imgbottom">
          <div class="imgdis flex flex-center flex-column" @click="setImg(defaultCards.def_Card1,1)">
            <img src="../assets/card1.jpg" :class="setClassId ? 'active' : ''" class="updateicon" />
          </div>
          <div class="imgdis flex flex-center flex-column" @click="setImg(defaultCards.def_Card2,2)">
            <img src="../assets/card2.jpg" :class="setClassId2 ? 'active' : ''" class="updateicon" />
          </div>
        </div>
      </div>
      <div class="rightvhtml">
        <div class="tit4">识别结果</div>
        <div class="flex mt60">
          <div class="tit5">银行卡卡号</div>
          <div class="tit6">{{card.bank_card_number}}</div>
        </div>
        <div class="flex mt60">
          <div class="tit5">有效期</div>
          <div class="tit6">{{card.valid_date}}</div>
        </div>
        <div class="flex mt60">
          <div class="tit5">银行名称</div>
          <div class="tit6">{{card.bank_name}}</div>
        </div>
        <div class="flex mt60">
          <div class="tit5">银行卡类型</div>
          <div class="tit6">{{card.bank_card_type}}</div>
        </div>
      </div>
    </div>
    <div class="flex flex-centers mt40">
      <div class="tit1"></div>
      <div class="tit2 ml10">其他</div>
    </div>
    <div class="other-box">
      <div class="other flex flex-center hovera" @click="setType(item.type,index)" v-for="(item, index) in datas" :key="index">
        <div>{{item.name}}</div>
        <img src="../assets/righttip.png" class="righttip ml10" />
      </div>
    </div>
  </div>
</template>
<script>

import def_Card1 from "@/assets/card1.jpg";
import def_Card2 from "@/assets/card2.jpg";

export  default {
  data(){
    return {
      datas: [
        {name: '身份证识别', type: 'idcard'}
      ],
      isFirst:true,
      isCard:true,
      imgUrlId:'',
      isIdFirst:true,
      loading: false,
      imgUrl: def_Card1,
      defaultCards:{
        def_Card1,
        def_Card2
      },
      // imgUrla: '',
      setClass: true,
      setClass2: false,
      setClassId: true,
      setClassId2: false,
      card:{
        bank_card_number:'6217 0024 5000 8692 286',
        valid_date:'03/26',
        bank_name:'建设银行',
        bank_card_type:'借记卡',
      },
    }
  },
  methods:{
    setType(type,index){
      this.$emit("chooseType", 0);
    },
    setImg(data,type) {
      this.imgUrl = data
      if (type === 1) {
        this.setClass = true
        this.setClass2 = false
        this.isFirst = true
        this.card.bank_card_number = '6217 0024 5000 8692 286'
        this.card.valid_date = '03/26'
        this.card.bank_name = '建设银行'
        this.card.bank_card_type = '借记卡'
      } else if (type === 2) {
        this.setClass = false
        this.setClass2 = true
        this.isFirst = false
        this.card.bank_card_number = '6221 6822 8480 8480'
        this.card.valid_date = '12/22'
        this.card.bank_name = '中国建设银行'
        this.card.bank_card_type = '贷记卡'
      }
    },
    //上传报错返回方法
    upImgError(err, file, fileList) {
      this.$message.error(`${JSON.parse(err.message).msg}`);
    },
    handlePreview(file) {
      console.log(file);
    },
    uploadCard(res, file, name) {
      if (res && res.code === 0) {
        this.imgUrl = 'https://ai.cicba.cn/ai-api' + res.data.originFile
        this.setClass = false
        this.setClass2 = false
        this.card = res.data.responseData
        if(this.card.bank_card_type == '0'){
          this.card.bank_card_type = '不能识别'
        } else if(this.card.bank_card_type == '1') {
          this.card.bank_card_type = '借记卡'
        }  else if(this.card.bank_card_type == '2') {
          this.card.bank_card_type = '贷记卡'
        }  else if(this.card.bank_card_type == '3') {
          this.card.bank_card_type = '准贷记卡'
        }  else if(this.card.bank_card_type == '4') {
          this.card.bank_card_type = '预付费卡'
        }
        // this.imgUrla = 'https://ai.cicba.cn/ai-api' + res.data.responseData
        this.loading = false
      }else{
        this.loading = false;
        this.$message.error(`${res.msg}`);
      }
    },
    beforeUpImg(file) {
      this.loading = true
      const isJPG = file.type === 'image/jpeg';
      const isGIF = file.type === 'image/gif';
      const isPNG = file.type === 'image/png';
      const isBMP = file.type === 'image/bmp';
      const isLt20M = file.size / 1024 / 1024 < 4;
      if (!isJPG && !isGIF && !isPNG && !isBMP) {
        this.$message.error(`上传图片必须是JPG/GIF/PNG/BMP 格式!`);
        this.loading = false
        return false
      }
      if (!isLt20M) {
        this.$message.error(`上传图片大小不能超过 4MB!`);
        this.loading = false
        return false
      }
      return (isJPG || isGIF || isPNG || isBMP) && isLt20M;
    }

  }

}
</script>
<style scoped>
.swipertop {
  width: 100%;
  height: 215px;
  background: #1a5df1;
  padding: 0 360px;
}
.aboutbg1img {
  width: 317px;
  height: 126px;
}
.aboutbg1img1 {
  width: 371px;
  height: 126px;
}
.aboutbg2img1 {
  width: 436px;
  height: 197.1px;
}
.coh1-1 {
  height: 100%;
}
.aboutbg2img {
  width: 436px;
  height: 198.1px;
}
.aboutbg2img2 {
  width: 436px;
  height: 190.2px;
}
.context {
  margin: 38px 0 8px 0;
  padding: 0 360px;
}
.txt1 {
  font-size: 18px;
  color: #1a5df1;
}

.arrowright {
  width: 15px;
  height: 15px;
  transform: rotate(90deg);
  margin: 2px 15px 0 15px;
}
.imgdis.active:after{
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  border: 2px solid #2B52F9;
  content: '';
}
.txt2 {
  font-size: 18px;
  color: #333333;
}
.vhtml {
  width: 100%;
  padding: 30px 40px;
  background: #fff;
  margin-top: 50px;
  position: relative;
}
.tit1 {
  width: 5px;
  height: 5px;
  background: #000000;
  border-radius: 50%;
}
.tit2 {
  font-size: 22px;
  font-weight: bold;
  color: #333333;
}
.tit3 {
  font-size: 16px;
  color: #9da3ab;
}
.aibgimg {
  position: absolute;
  z-index: 0;
  width: 311px;
  height: 227px;
  top: 116px;
  right: 0;
}
.leftvhtml {
  width: calc(100% - 376px);
  border: 1px solid #cccccc;
}
.allvhtml {
  padding: 27px 27px 0 27px;
  background: #e9edfe;
  position: relative;
  z-index: 1;
}
.rightvhtml {
  width: 376px;
  padding: 70px 10px 20px 50px;
  background: #2b52f9;
  position: relative;
  z-index: 1;
}
.other {
  margin-top: 30px;
  width: 220px;
  height: 55px;
  background: rgba(43, 82, 249, 0.05);
  line-height: 55px;
  text-align: center;
  font-size: 20px;
  color: #205cf4;
}
.tit4 {
  font-size: 20px;
  color: #ffffff;
  line-height: 30px;
}
.tit5 {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  line-height: 30px;
  opacity: 0.5;
  width: 110px;
}
.tit6 {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  line-height: 30px;
  opacity: 0.98;
  width: calc(100% - 110px);
}
.imgcontent {
  padding: 62px 92px;
}
.imgcontent2 {
  padding: 55px 50px;
}
.distinguishimg {
  width: 100%;
  height: 334px;
}
.update {
  padding: 0 50px;
  height: 68px;
  background: rgba(43, 82, 249, 0.05);
}
.tit7 {
  font-size: 14px;
  color: #333333;
  opacity: 0.5;
}
.btnupdate {
  width: 104px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  background: #2b52f9;
  font-size: 14px;
  color: #ffffff;
  line-height: 30px;
}
.imgbottom {
  padding: 30px 50px;
}
.imgdis {
  background: RGBA(243, 245, 254, 1);
  width: 220px;
  height: 142px;
  margin-right: 30px;
}
.updateicon {
  width: 100%;
  height: 100%;
}
.updatetext {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
  margin-top: 20px;
}
.imgdiss {
  background: #f3f5fe;
  padding: 25px 20px;
}
.imgdis:last-child {
  margin-right: 0;
}
.imgcontent1 {
  width: 50%;
}
.imgd {
  width: 100%;
  height: 359px;
}
.handle {
  width: 99px;
  height: 38px;
  line-height: 38px;
  border: 2px solid #bfbfbf;
  text-align: center;
  color: #bfbfbf;
  font-size: 16px;
}
.h100 {
  height: 100px;
}
.b {
  border: 1px solid #cccccc;
}
.col3-1 {
  width: 33.33%;
}
.tit8 {
  font-size: 17px;
  font-weight: bold;
  color: #333333;
}
.tit9 {
  font-size: 15px;
  color: #333333;
  line-height: 24px;
  opacity: 0.5;
}
.flex3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
</style>
